"use client";

import { Masonry } from "@/components/Masonry";
import Image from "next/image";
import Link from "next/link";
import * as React from "react";
import { HomeQueryVariables, useHomeQuery } from "src/graphql/queries";
import { useImmer } from "use-immer";
import imageLoader from "src/imageLoader";
import { Provider, createClient, fetchExchange } from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
const initialVariables: HomeQueryVariables = {
  first: 30,
  after: null
};
function Page() {
  const [state, mutate] = useImmer({
    variables: initialVariables
  });
  const [result] = useHomeQuery({
    variables: state.variables
  });
  const containerRef = React.useRef<null | HTMLDivElement>(null);
  const blobs = result.data?.blobs;
  const loadMore = React.useCallback(() => {
    if (!blobs || !blobs.pageInfo) {
      return;
    }
    if (!blobs.pageInfo.hasNextPage) {
      return;
    }
    if (!containerRef.current) {
      return;
    }
    if (containerRef.current.getBoundingClientRect().bottom >= window.innerHeight + 600) {
      return;
    }
    requestAnimationFrame(() => {
      mutate(draft => {
        draft.variables.after = blobs.edges[blobs.edges.length - 1].cursor;
      });
    });
  }, [blobs, mutate]);
  React.useEffect(() => {
    loadMore();
    window.addEventListener("scroll", loadMore, {
      passive: true
    });
    window.addEventListener("resize", loadMore, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", loadMore);
      window.removeEventListener("resize", loadMore);
    };
  }, [loadMore]);
  return <div>
      <div ref={containerRef} className={"d1bdmy7h"}>
        <Masonry id="home">
          {result.data?.blobs?.edges?.map(({
          node
        }) => <Link key={node.id} href={`/b/${node.name}`} prefetch={false} className={"l1o6sjk5"}>
              {(() => {
            if (node.asImage) {
              return <Image alt="" sizes="(min-width: 1376px) 25vw, (min-width: 1060px) 30vw, (min-width: 744px) 50vw, 100vw" src={node.asImage.url} loader={imageLoader} {...node.asImage?.dimensions} style={{
                display: "block",
                width: "100%",
                height: "auto",
                backgroundColor: "#000000"
              }} />;
            }
            if (node.asVideo) {
              return <Image alt="" sizes="(min-width: 1376px) 25vw, (min-width: 1060px) 30vw, (min-width: 744px) 50vw, 100vw" src={node.asVideo.poster.url} loader={imageLoader} {...node.asVideo.poster.dimensions} style={{
                display: "block",
                width: "100%",
                height: "auto",
                backgroundColor: "#000000"
              }} />;
            }
            return null;
          })()}
            </Link>)}
        </Masonry>
      </div>
    </div>;
}
const client = createClient({
  url: `${process.env.API}/graphql`,
  fetchOptions: {
    credentials: "include"
  },
  exchanges: [devtoolsExchange, cacheExchange({
    keys: {
      Dimensions: () => null
    },
    resolvers: {
      Query: {
        blobs: relayPagination()
      }
    }
  }), fetchExchange]
});
export default (() => <Provider value={client}>
    <Page />
  </Provider>);

require("./page.linaria.module.css");